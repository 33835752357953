import React, { useEffect, useState } from 'react';
import './Cookie.css';

const Cookie = ({ setConsent }) => {
    const [showCookie, setShowCookie] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem('consentGiven');
        if (!consentGiven) setShowCookie(true);
        else setConsent(true);
    }, [setConsent]);

    const handleAcceptCookies = () => {
        localStorage.setItem('consentGiven', 'true');
        setShowCookie(false);
        setConsent(true);
    };

    const handleRejectCookies = () => {
        setShowCookie(false);
        setConsent(false);
    };

    if (!showCookie) return null;

    return (
        <div id="privacy-notice" className="privacy-notice">
            <div className="cookie-content">
                <div className="cookie-icon">
                    🍪
                </div>
                <div className="cookie-text">
                    <p>
                        We use cookies to enhance your experience. By continuing to browse, you agree to our use of cookies.{' '}
                        <a href="/privacy-policy">Learn more</a>.
                    </p>
                </div>
            </div>
            <div className="buttons">
                <button className="accept-button" onClick={handleAcceptCookies}>
                    Accept
                </button>
                <button className="reject-button" onClick={handleRejectCookies}>
                    Reject
                </button>
            </div>
        </div>
    );
};

export default Cookie;