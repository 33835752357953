import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import '../../styles/global.css';
import './VideoList.css';

import defaultProfilePic from '../../assets/images/default-pfp.jpg'; 

const YouTubeList = () => {
    const [videos, setVideos] = useState([]);
    const [filteredVideos, setFilteredVideos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [sortOrder, setSortOrder] = useState('date');

    const durationToSeconds = (duration) => {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = duration.match(regex);

        return (parseInt(matches[1] || 0, 10)*3600) + (parseInt(matches[2] || 0, 10)*60) + parseInt(matches[3] || 0, 10);
    }
    
    const sortVideos = (order) => {
        let sortedVideos = [...filteredVideos];
        
        if (order === 'date') {
            sortedVideos.sort((a, b) => new Date(b.video.published_at) - new Date(a.video.published_at));
        } else if (order === 'views') {
            sortedVideos.sort((a, b) => b.video.view_count - a.video.view_count);
        } else if (order === 'duration') {
            sortedVideos.sort((a, b) => {
                const durationA = durationToSeconds(a.video.duration);
                const durationB = durationToSeconds(b.video.duration);
                return durationB - durationA
            })
        }
    
        setFilteredVideos(sortedVideos);
        setSortOrder(order);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('/api/youtube/videos');
                const data = await response.json();
                setVideos(data);
                setFilteredVideos(data);
            } catch (error) {
                console.error('Error loading videos:', error);
                setVideos([]);
                setFilteredVideos([]);
                setErrorMessage('There was an issue loading the YouTube videos. Please try again later.');
            }
        };

        fetchData();
    }, []);

    const search = (event) => {
        const input = event.target.value.toLowerCase();
        setSearchTerm(input);
        const filtered = videos.filter(video =>
            video.video.title.toLowerCase().includes(input) ||
            video.video.description.toLowerCase().includes(input) ||
            video.channel.name.toLowerCase().includes(input)
        );
        setFilteredVideos(filtered);
    };

    const cleanUp = (string) => {
        return string.replace("&#39;", "'")     

    }

    const handleRedirect = (video) => {
        window.open(`https://www.youtube.com/watch?v=${video.id}`, '_blank');
    };
    
    const format = (string) => {
        return string.replace("&#39;", "'")
    }

    const timeAgo = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();

        const secondsAgo = Math.floor((now - date) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        const weeksAgo = Math.floor(daysAgo / 7);
        const monthsAgo = Math.floor(daysAgo / 30);
        const yearsAgo = Math.floor(daysAgo / 365);

        if (secondsAgo < 60) {
            return `${secondsAgo} second${secondsAgo !== 1 ? 's' : ''} ago`;
        } else if (minutesAgo < 60) {
            return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
        } else if (hoursAgo < 24) {
            return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
        } else if (daysAgo < 7) {
            return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
        } else if (weeksAgo < 4) {
            return `${weeksAgo} week${weeksAgo !== 1 ? 's' : ''} ago`;
        } else if (monthsAgo < 12) {
            return `${monthsAgo} month${monthsAgo !== 1 ? 's' : ''} ago`;
        } else {
            return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
        }
    }

    const convertYoutubeDuration = (duration) => {
        const regex = /PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/;
        const matches = duration.match(regex);

        const hours = parseInt(matches[1] || 0, 10);
        const minutes = parseInt(matches[2] || 0, 10);
        const seconds = parseInt(matches[3] || 0, 10);

        const formattedHours = hours > 0 ? `${hours}:` : '';
        const formattedMinutes = hours > 0 ? String(minutes).padStart(2, '0') : minutes;
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}${formattedMinutes}:${formattedSeconds}`
    }

    return (
        <Layout>
            <div className="main-container">
                <div className="left-controls">
                    <div className="sort-filter">
                        <label>Sorting type</label>
                        <p></p>
                        <select 
                            value={sortOrder} 
                            onChange={(e) => sortVideos(e.target.value)}
                        >
                            <option value="date">Date</option>
                            <option value="views">Views</option>
                            <option value="duration">Length</option>
                        </select>
                    </div>
                </div>

                <div className="title-search-container">
                    <div className='title'>
                        <h1>Youtube Video List</h1>
                    </div>
                    <div className="search-container">
                        <input
                            type="text"
                            id="containerSearchBar"
                            placeholder="Search for a video..."
                            value={searchTerm}
                            onChange={search}
                        />
                    </div>
                </div>

                <div className="filter-info">
                    <p>Welcome to the Video List!</p>
                    <p>Here you can find the most recently uploaded Skyblock related videos.</p>
                    <p>Use the search bar if you're looking for something specific.</p>
                    <p>Sort videos using the dropdown menu on the left. Default is date.</p>
                    <p>Videos are updated every two hours.</p>
                    <p>If your video isn't showing up, please make sure that:</p>
                    <p>The title contains "#hypixelskyblock" or "#hypixel #skyblock" tag</p>
                </div>
            </div>

            <div className="video-grid">
                {filteredVideos.map((video) => (
                    <div
                        key={video.id}
                        className="item youtube-item"
                        onClick={() => handleRedirect(video)}
                    >
                        <div className="item-content">
                            <div className="item-thumbnail">
                                <img
                                    className="thumbnail-img"
                                    src={video.video.thumbnail_url || 'default-thumbnail-url.jpg'}
                                    alt={`${cleanUp(video.video.title) || 'Video Thumbnail'}`}
                                />
                                <div className='thumbnail-info-right'>
                                    <span key='views' className="info">
                                        {`👀 ${video.video.view_count?.toLocaleString() || "0"}`}
                                    </span>
                                    <span key='likes' className="info">
                                        {`👍 ${video.video.like_count?.toLocaleString() || "0"}`}
                                    </span>
                                    <span key='comms' className="info">
                                        {`💬 ${video.video.comment_count?.toLocaleString() || "0"}`}
                                    </span>
                                </div>
                            </div>
                            <div className="thumbnail-details-bottom">
                                <span key='duration' className='info'>
                                    {`⏱️ ${convertYoutubeDuration(video.video.duration)}`}
                                </span>
                                <span key='date' className="info">
                                    {`📅 ${timeAgo(video.video.published_at)}`}
                                </span>
                            </div>


                            <div className="details">
                                <h4>{format(video.video.title) || 'No Title'}</h4>
                                <div className="item-footer">
                                    <div className="icon-name">
                                        <img
                                            width="60"
                                            height="60"
                                            src={video.channel.profile_url || defaultProfilePic}
                                            onError={() => handleImageError(video.channel.id)}
                                            alt={`${video.channel.name || 'Channel Icon'}`}
                                        />
                                        <h3>{video.channel.name || 'Unknown Channel'}</h3>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {errorMessage && <div id="error-message" className="error-message">{errorMessage}</div>}
        </Layout>
    );
};

export default YouTubeList;