import React, { useEffect, useRef } from 'react';
import './Modal.css';

const Modal = ({ show, onClose, title, iconSrc, description, features, link }) => {
    const modalRef = useRef(null);

    const handleClickOutside = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            onClose();
        }
    };

    useEffect(() => {
        if (show) {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [show]);

    if (!show) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" ref={modalRef}>
                <div className="modal-header">
                    <img src={iconSrc} alt={`${title} Icon`} className="modal-icon" />
                    <h2 className="modal-title">{title}</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                <div className="modal-body">
                    <p className="modal-description">{description}</p>
                    <ul className="modal-features">
                        {features.map((feature, index) => (
                            <li key={index}>
                                <span className="feature-icon">{feature.icon}</span>
                                <strong>{feature.label}:</strong> {feature.value}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="modal-footer">
                    <a href={link} className="cta-button">Join the Discord Server</a>
                </div>
            </div>
        </div>
    );
};

export default Modal;