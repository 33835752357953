import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout/Layout';
import Modal from '../../components/Modal/Modal';
import '../../styles/global.css';
import './DiscordList.css';

const emojiDictionary = {
    "combat": "🗡️",
    "mining": "⛏️",
    "farming": "🌾",
    "foraging": "🪓",
    "fishing": "🎣",
    "dungeon": "💀",
    "bestiary": "🐉",
    "slayer": "👿",
    "money": "💸",
    "trading": "🤝",
    "carry": "🚚",
    "guild": "🌐",
    "community": "🏳️",
    "content creator": "🎥",
    "mod": "🛠️",
    "dedicated grind": "🎯",
};

const capitalizeWords = (str) => {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
};

const getDiscordData = async (inviteUrl) => {
    const inviteCode = inviteUrl.split('/').pop();
    const response = await fetch(`https://discord.com/api/v10/invites/${inviteCode}?with_counts=true`);
    const data = await response.json();
    return {
        expiration_date: data.expires_at,
        id: data.guild.id,
        name: data.guild.name,
        splash: data.guild.splash,
        banner: data.guild.banner,
        description: data.guild.description,
        icon: data.guild.icon,
        features: data.guild.features,
        nsfw_level: data.guild.nsfw_level,
        nsfw: data.guild.nsfw,
        vanity_url_code: data.guild.vanity_url_code,
        premium_subscription_count: data.guild.premium_subscription_count,
        total: data.approximate_member_count,
        online: data.approximate_presence_count
    };
};

const DiscordList = ({ consent }) => {
    const [discordServers, setDiscordServers] = useState([]);
    const [filteredServers, setFilteredServers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedTags, setSelectedTags] = useState([]);
    const [modalData, setModalData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const cacheKey = 'discordServers';
            const cacheExpiry = 1000;//12960000;
            const cachedData = consent && localStorage.getItem(cacheKey);
            const cacheTimestamp = consent && localStorage.getItem(`${cacheKey}_timestamp`);
        
            if (cachedData && cacheTimestamp && (Date.now() - cacheTimestamp < cacheExpiry)) {
                setDiscordServers(JSON.parse(cachedData));
                setFilteredServers(JSON.parse(cachedData));
            } else {
                try {
                const response = await fetch('/api/discord/servers');
                const data = await response.json();
                console.log(data);
                const servers = await Promise.all(data.map(async item => {
                    try {
                    const discord = await getDiscordData(item.invite);
                    if (discord.id === item.id) {
                        return { ...item, discord };
                    }
                    } catch (error) {
                    console.error(`Error fetching data for invite ${item.invite}:`, error);
                    }
                }));
                const filtered = servers.filter(server => server !== undefined);
                filtered.sort((a, b) => b.discord.total - a.discord.total);
        
                if (consent) {
                    localStorage.setItem(cacheKey, JSON.stringify(filtered));
                    localStorage.setItem(`${cacheKey}_timestamp`, Date.now());
                }
        
                setDiscordServers(filtered);
                setFilteredServers(filtered);
                } catch (error) {
                console.error('Error loading discord servers:', error);
                setDiscordServers([]);
                setFilteredServers([]);
                setErrorMessage('There was an issue loading the Discord servers. Please try again later.');
                }
            }
        };
    
        fetchData();
      }, [consent]);

    const search = (event) => {
        const input = event.target.value.toLowerCase();
        setSearchTerm(input);
        const filtered = discordServers.filter(server =>
            server.discord.name?.toLowerCase().includes(input) ||
            server.discord.description?.toLowerCase().includes(input)
        );
        setFilteredServers(filtered);
    };

    const filter = (event) => {
        const tag = event.target.value;
        setSelectedTags(prevTags => {
            const newTags = prevTags.includes(tag) ?
                prevTags.filter(t => t !== tag) :
                [...prevTags, tag];
            const filtered = discordServers.filter(server => {
                const categories = server.categories || [];
                return newTags.every(tag => categories.includes(tag));
            });
            setFilteredServers(filtered);
            return newTags;
        });
    };

    const handleShowModal = (item) => {
        setModalData({
            title: item.discord.name,
            iconSrc: `https://cdn.discordapp.com/icons/${item.discord.id}/${item.discord.icon}.jpg`,
            description: item.discord.description,
            features: [
                { icon: "👥", label: "Total Members", value: item.discord.total },
                { icon: "⚡", label: "Online Members", value: item.discord.online },
                { icon: "⭐", label: "Nitro Boosts", value: item.discord.premium_subscription_count }
            ],
            link: item.download
        });
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    return (
        <Layout>
            <div className='title'>
                <h1>Discord List</h1>
            </div>
            <div className="filters">
                <input
                    type="text"
                    id="searchBar"
                    placeholder="Search for a server..."
                    value={searchTerm}
                    onChange={search}
                />
                <div id="tags">
                    {["dedicated-grinds", "mods", "communities", "content-creators", "guilds"].map(tag => (
                        <React.Fragment key={tag}>
                            <input
                                type="checkbox"
                                id={tag}
                                value={tag}
                                checked={selectedTags.includes(tag)}
                                onChange={filter}
                            />
                            <label htmlFor={tag}>{capitalizeWords(tag.replace('-', ' '))}</label>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <div id="discordList" className="list-container">
                {filteredServers.map(item => (
                    <div
                        key={item.id}
                        className="item"
                        onClick={() => handleShowModal(item)}
                        onMouseEnter={() => setHoveredItem(item.id)}
                        onMouseLeave={() => setHoveredItem(null)}
                    >
                        <div className="tags">
                            {item.tags.map(tag => (
                                <span
                                    key={tag}
                                    className="tag"
                                    data-tag={tag}
                                >
                                    {hoveredItem === item.id
                                        ? capitalizeWords(tag)
                                        : emojiDictionary[tag] || capitalizeWords(tag)}
                                </span>
                            ))}
                        </div>
                        <div className="icon-name">
                            <img
                                width="60"
                                height="60"
                                src={`https://cdn.discordapp.com/icons/${item.discord.id}/${item.discord.icon}.jpg`}
                                alt={`${item.discord.name} Icon`}
                            />
                            {hoveredItem === item.id ? (
                                <h3>{item.discord.name}</h3>
                            ) : (
                                <h3>{item.discord.name}</h3>
                            )}
                        </div>
                        
                        <div className="item-content">
                            <p>Total Members: {item.discord.total}</p>
                            <p>Online Members: {item.discord.online}</p>
                        </div>
                    </div>
                ))}
            </div>
            {errorMessage && <div id="error-message" className="error-message">{errorMessage}</div>}
            {modalData && (
                <Modal
                    show={showModal}
                    onClose={closeModal}
                    title={modalData.title}
                    iconSrc={modalData.iconSrc}
                    description={modalData.description}
                    features={modalData.features}
                    link={modalData.link}
                />
            )}
        </Layout>
    );
};

export default DiscordList;