import React, { useState } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Cookie from '../Cookie/Cookie';
import './Layout.css';

const Layout = ({ children }) => {
  const [consent, setConsent] = useState(false);

  return (
    <div className="layout">
      <Cookie setConsent={setConsent} />
      <Header />
      <main className="content">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
