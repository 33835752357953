import React from 'react';
import Layout from '../../components/Layout/Layout';
import '../../styles/global.css';
import './Home.css';

const Home = () => {
  return (
    <Layout>
      <div className="home">
        <div className="about-us">
          <p>
          SkyAli aims to provide a secure platform to list and access all verified Discord servers, ensuring ease of use and safety while helping you easily find entertainment such as videos or streams. 
          </p>
          <p>
          We are two Hypixel Skyblock fans, Alifarce and Doflo, who wanted to create a useful space for everyone. Don’t hesitate to share and support our project. If you have any suggestion, feel free to contact us on Discord. We are always open to feedback!
          </p>
        </div>
        <div className="team">
          <div className="member">
            <h2>Alifarce</h2>
            <p>
              I'm a second-year computer science student who likes to code various projects in his free time, former top player in both normal and stranded profiles, developer of SkyAli.
            </p>
          </div>
          <div className="member">
            <h2>Doflo</h2>
            <p>
              I'm a third-year economics student, gemstone massacre victim, currently the #2 stranded player, aproviding project ideas and collaborating with Alifarce on them! Stranded gaming!
            </p>
          </div>
        </div>
        <div className="cookies-info">
          <h2>How we handle cookies</h2>
          <p>
            We only use cookies to lighten the traffic with the server, by sending new requests only after a set amount of time instead of everytime you refresh the page. No sensitive information is stored at any point. We respect your privacy and we will never share your data with third parties. If you have any questions, feel free to contact us on Discord. We are always happy to help! 😊
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Home;